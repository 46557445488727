<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-modal v-model="showModal" title="Confirmation" hide-footer>
              <form>
                <div class="form-group row mb-4">
                  <label for="title" class="col-form-label col-lg-2">Numéro pièce identité</label>
                  <div class="col-lg-10">
                    <input disabled v-model="data.cni_numero" id="id_number" name="id_number" type="text" class="form-control" />
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Nom</label>
                  <div class="col-lg-10">
                    <input disabled v-model="data.nom" id="nom" name="nom" type="text" class="form-control" />
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Prenom</label>
                  <div class="col-lg-10">
                    <input disabled v-model="data.prenom" id="prenom" name="prenom" type="text" class="form-control" />
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Alias</label>
                  <div class="col-lg-10">
                    <input disabled v-model="data.alias" id="alias" name="alias" type="text" class="form-control"  />
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Sexe</label>
                  <div class="col-lg-10">
                    <multiselect disabled class="test-primary" v-model="data.sexe" :options="sexe"></multiselect>
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="title" class="col-form-label col-lg-2">Date de naissance</label>
                  <div class="col-lg-10">
                    <input disabled v-model="data.date_naissance" type="date" class="form-control">
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label disabled for="title" class="col-form-label col-lg-2">Nationalité</label>
                  <div class="col-lg-10">
                    <input disabled v-model="data.nationalite" type="text" class="form-control">
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Taille(m)</label>
                  <div class="col-lg-10">
                    <input disabled id="height" name="height" v-model="data.taille" type="text" class="form-control"/>
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Poids(Kg)</label>
                  <div class="col-lg-10">
                    <input disabled id="weight" name="weight" v-model="data.poids" type="text" class="form-control"/>
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Teint</label>
                  <div class="col-lg-10">
                    <input disabled id="teint" name="teint" v-model="data.teint" type="text" class="form-control"/>
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Signes particuliers</label>
                  <div class="col-lg-10">
                    <input disabled id="teint" name="teint" v-model="data.signe_particulier" type="text" class="form-control"/>
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Motif de recherche</label>
                  <div class="col-lg-10">
                    <input disabled id="teint" name="teint" v-model="data.motif_recherche" type="text" class="form-control"/>
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Niveau de dangérosité</label>
                  <div class="col-lg-10">
                    <multiselect disabled class="form-control" v-model="data.niveau_dangerosite" :options="niveau"></multiselect>
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Conduite à tenir</label>
                  <div class="col-lg-10">
                    <input disabled type="text" class="form-control" v-model="data.conduite_a_tenir">
                  </div>
                </div>
                <div class="row justify-content-end">
                  <div class="col-md-10">
                    <button type="button" @click="showModal = false" class="btn btn-primary">
                      Annuler
                    </button>
                    <button @click="addPersonne()" type="button" class="btn btn-success" :disabled="spanView"
                      style="float: right">
                      <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                      Valider
                    </button>
                  </div>
                </div>
              </form>
            </b-modal>
            <form>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Numéro pièce identité</label>
                <div class="col-lg-10">
                  <input v-model="data.cni_numero" id="id_number" name="id_number" type="text" class="form-control"
                    placeholder="Renseignez le numéro de la pièce d'identité..." />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Nom</label>
                <div class="col-lg-10">
                  <input v-model="data.nom" id="nom" name="nom" type="text" class="form-control"
                    placeholder="Renseignez le nom de la personne..." />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Prenom</label>
                <div class="col-lg-10">
                  <input v-model="data.prenom" id="prenom" name="prenom" type="text" class="form-control"
                    placeholder="Renseignez le prenom de la personne..." />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Alias</label>
                <div class="col-lg-10">
                  <input v-model="data.alias" id="alias" name="alias" type="text" class="form-control"
                    placeholder="Renseignez l'alias de la personne..." />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Sexe</label>
                <div class="col-lg-10">
                  <multiselect class="test-primary" v-model="data.sexe" :options="sexe"></multiselect>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Date de naissance</label>
                <div class="col-lg-10">
                  <input v-model="data.date_naissance"  placeholder="Date de naissance" type="date" class="form-control">
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Nationalité</label>
                <div class="col-lg-10">
                  <input v-model="data.nationalite"  placeholder="Nationalité" type="text" class="form-control">
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Taille(m)</label>
                <div class="col-lg-10">
                  <input id="height" name="height" v-model="data.taille" type="text" class="form-control"
                    placeholder="Renseignez la taille de la personne..." />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Poids(Kg)</label>
                <div class="col-lg-10">
                  <input id="weight" name="weight" v-model="data.poids" type="text" class="form-control"
                    placeholder="Renseignez le poids de la personne..." />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Teint</label>
                <div class="col-lg-10">
                  <input id="teint" name="teint" v-model="data.teint" type="text" class="form-control"
                    placeholder="Renseignez le teint de la personne..." />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Signes particuliers</label>
                <div class="col-lg-10">
                  <input id="teint" name="teint" v-model="data.signe_particulier" type="text" class="form-control"
                    placeholder="Renseignez les signes particuliers de la personne..." />
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Motif de recherche</label>
                <div class="col-lg-10">
                  <input id="teint" name="teint" v-model="data.motif_recherche" type="text" class="form-control"
                    placeholder="Donnez le motif ou raison de la recherche..." />
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Niveau de dangérosité</label>
                <div class="col-lg-10">
                  <multiselect class="form-control" v-model="data.niveau_dangerosite" :options="niveau"></multiselect>
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Conduite à tenir</label>
                <div class="col-lg-10">
                  <input type="text" placeholder="Conduite à tenir" class="form-control" v-model="data.conduite_a_tenir">
                </div>
              </div>

              <div class="form-group row mb-4">
                <label class="col-form-label col-lg-2">Fichiers associés</label>
                <div class="col-lg-10">
                  <VueFileAgent :deletable="true" :maxSize="'150MB'" :maxFiles="20" :meta="true" :multiple="true"
                    :accept="'image/*,video/mp4,.pdf'" :uploadUrl="uploadUrl" v-model="data.photos" />
                </div>
              </div>

              <div class="row justify-content-end">
                <div class="col-md-10">
                  <button type="reset" class="btn btn-primary">
                    Annuler
                  </button>
                  <button @click="submit()" type="button" class="btn btn-success" :disabled="spanView"
                    style="float: right">
                    <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                    Enregistrer la personne
                  </button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
// import Datepicker from "vue3-datepicker";
import Multiselect from "@vueform/multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';
import Swal from "sweetalert2";
import { VueFileAgent } from '@boindil/vue-file-agent-next';
import '@boindil/vue-file-agent-next/dist/vue-file-agent-next.css';
import axios from 'axios';

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    VueFileAgent,
    // Datepicker
  },
  data() {
    return {
      title: "Ajouter une personne recherchée",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Personne recherchée",
          active: true,
        },
      ],
      sexe: [
        { value: 'masculin', label: 'Masculin' },
        { value: 'feminin', label: 'Feminin' }
      ],
      niveau: [{ value: 'normal', label: 'Normal' },
      { value: 'dangereux', label: 'Dangereux' },
      { value: 'tres_dangereux', label: 'Très dangereux' },],

      data: {
        cni_numero: null,
        nom: null,
        prenom: null,
        alias: null,
        date_naissance: null,
        taille: null,
        poids: null,
        teint: null,
        sexe: null,
        signe_particulier: null,
        motif_recherche: null,
        niveau_dangerosite: null,
        conduite_a_tenir: null,
        statut: 'rechercher',
        photos: []
      },
      images: [],
      spanView: false,
      showModal: false,
      authError: null,
      response: null,
      selectedDate: null,
    };
  },
  methods: {
    ...reqMethods,
    async addPersonne() {
      this.showModal = false;
      this.spanView = true;
      const that = this;
      const formData = new FormData();
      formData.append('cni_numero', this.data.cni_numero);
      formData.append('nom', this.data.nom);
      formData.append('prenom', this.data.prenom);
      formData.append('alias', this.data.alias);
      formData.append('taille', this.data.taille);
      formData.append('poids', this.data.poids);
      formData.append('teint', this.data.teint);
      formData.append('date_naissance', this.data.date_naissance);
      formData.append('nationalite', this.data.nationalite);
      formData.append('signe_particulier', this.data.signe_particulier);
      formData.append('motif_recherche', this.data.motif_recherche);
      formData.append('niveau_dangerosite', this.data.niveau_dangerosite);
      formData.append('conduite_a_tenir', this.data.conduite_a_tenir);
      formData.append('status', this.data.statut);
      formData.append('sexe', this.data.sexe);
      for (const photo of this.data.photos) {
        formData.append('photos', photo.file);
      }
      try {
        const response = await axios.post('https://api.alcit.sims-technologie.com/api/v1/personne/personne/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token'),
          },
        });
        Swal.fire(response.data.message, "Personne Ajouté!", "success");
        that.spanView = false;
        this.$router.push({ path: `/liste-personne` });
      } catch (error) {
        that.spanView = false;
        that.authError = error;
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors de l'ajout de la personne",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    submit(){
      this.showModal = true;
    }
  },
};
</script>
